import Navbar from "../../components/Navbar";
import Hero from "../../assets/img/TaptapHero.png";
// import Widgetss from "../../assets/img/widgets.svg";
import Rates from "../../assets/img/rates.png";
import Support from "../../assets/img/support.png";
import Apple from "../../assets/img/AppStore.svg";
import Google from "../../assets/img/GooglePlay.svg";
// import Faq from "../../assets/img/faq.png";
import Events from "../../assets/img/event.svg";
// import FaqComponent from "../../components/Faq";
import Widgets from "../../components/Widgets";
import HeroButton from "../../components/HeroButton";

const Landing = () => {
  return (
    <div className="snap-y snap-mandatory md:h-screen overflow-scroll">
      <Navbar />

      {/* Hero section */}
      <section
        id="hero"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto my-8 md:my-0">
          <img src={Hero} alt="Tap tap hero" className="block md:hidden" />
          <HeroButton />
        </div>

        <div className="col-span-3 bg-black text-white flex items-center ">
          <div className="p-6 md:mx-16">
            <h1 className="text-4xl md:text-7xl font-bold mb-10 md:mb-20 leading-tight">
              Simple, Stress-Free Bill Payments.
            </h1>
            <span class="button">
              <div class="dots_border"></div>
              <span class="text_button">Ease of Use</span>
            </span>
            {/* <span className="rounded-full px-[1px] py-[9px] bg-gradient-to-r from-gray-700 to-gray-200">
            <span className=" bg-black rounded-full px-4 py-2">Ease of Use</span>
            </span> */}
            <p className="mt-10 md:text-2xl font-light">
              Paying your bills is now as easy as a quick Tap Tap. Seriously,
              it’s that easy, we promise.
            </p>
          </div>
        </div>
      </section>

      {/* Widgets section */}
      <section
        id="widgets"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto my-6 md:my-0">
          {/* <img src={Widgetss} alt="Tap tap widgets" className="w-[300px] block md:hidden" /> */}
          <Widgets />
        </div>

        <div className="col-span-3 bg-black text-white flex items-center ">
          <div className="p-6 md:mx-20">
            <h1 className="text-4xl md:text-7xl font-bold mb-10 md:mb-20 leading-tight">
              Paying Your Bills Shouldn't Be a Chore.
            </h1>
            <span class="button">
              <div class="dots_border"></div>
              <span class="text_button">Convenient</span>
            </span>

            <p className="mt-10 md:text-2xl font-light">
              You've worked hard to pay your bills, the process shouldn't feel
              like <br /> another job.
            </p>
          </div>
        </div>
      </section>

      {/* Rates section */}
      <section
        id="rates"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto mb-6 md:mb-0">
          <img src={Rates} alt="Tap tap hero" />
        </div>

        <div className="col-span-3 bg-black text-white flex items-center ">
          <div className="p-6 md:mx-20 md:w-3/4">
            <h1 className="text-4xl md:text-7xl font-bold mb-10 md:mb-20 leading-tight">
              Big Savings, Small Cost.
            </h1>
            <span class="button">
              <div class="dots_border"></div>
              <span class="text_button">Value For Money</span>
            </span>

            <p className="mt-10 md:text-2xl font-light">
              Get the best rates for your utilities and bills, prices so good,
              they feel like a steal.{" "}
            </p>
          </div>
        </div>
      </section>

      {/* Support section */}
      <section
        id="support"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto mb- md:mb-0">
          <img src={Support} alt="Tap tap hero" />
        </div>

        <div className="col-span-3 bg-black text-white flex items-center">
          <div className="p-6 md:mx-20 md:w-3/4">
            <h1 className="text-4xl md:text-7xl font-bold mb-10 md:mb-20 leading-tight">
              You Don't Have To Call.
            </h1>
            <span class="button">
              <div class="dots_border"></div>
              <span class="text_button">We Are Proactive</span>
            </span>

            <p className="mt-10 md:text-2xl font-light">
              Our mission is to stay connected with you at every step. We'll be
              in touch before you even think of reaching out.{" "}
            </p>
          </div>
        </div>
      </section>

      {/* Event section */}
      <section
        id="event"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto my-8 md:my-0">
          <img src={Events} alt="Tap tap hero" />
        </div>

        <div className="col-span-3 bg-black text-white flex items-center ">
          <div className="p-6 md:mx-20 md:w-3/4">
            <h1 className="text-4xl md:text-7xl font-bold mb-10 md:mb-20 leading-tight">
              It's Not All Business, We Are Also Fun.
            </h1>
            <span class="button">
              <div class="dots_border"></div>
              <span class="text_button">Fun and Games</span>
            </span>

            <p className="mt-10 md:text-2xl font-light">
              Join us every month for online and offline events that are packed
              with fun games and a chance to win fantastic prizes.{" "}
            </p>
          </div>
        </div>
      </section>

      {/* Faq section */}
      {/* <section
        id="faq"
        className="snap-start h-full md:h-screen md:grid grid-cols-4"
      >
        <div className="col-span-1 flex items-center justify-center mx-auto my-8 md:my-0">
          <img src={Faq} alt="Tap tap hero" />
        </div>

        <div className="col-span-3 bg-black text-white flex">
          <div className="p-8 md:mx-20">
            <h1 className="mt-24 text-5xl font-bold mb-20 leading-tight">
              Faqs
            </h1>
            <div>
              <FaqComponent />
            </div>
          </div>
        </div>
      </section> */}

      {/* Waitlist section */}
      <section
        id="waitlist"
        className="snap-start bg-black h-full md:h-screen "
      >
        <div className=" text-white flex justify-center items-center">
          <div className="md:mx-20 text-center px-4 py-20 md:py-48">
            <h1 className="text-5xl md:text-8xl font-bold">Ready to Tap In?</h1>
            <p className="my-14">
              Get early access to simplified bill payments with some extra
              perks, like first dibs on new features,
              <br /> a chance to attend exclusive events, and opportunities to
              win great prizes while we shape the <br />
              future of TapTap together.
            </p>
            <a
              href="/waitlist"
              className="bg-white text-2xl py-3 px-6 lg:px-40 rounded-full text-black hover:opacity-70 duration-300"
            >
              Join the Waitlist
            </a>
            <hr className="mt-20 opacity-50" />
            <div className="mt-16">
              <h3 className="font-bold text-3xl mb-8">COMING SOON</h3>
              <div className="flex justify-center gap-6 opacity-60">
                <button className="cursor-not-allowed">
                  <img src={Apple} alt="apple download" />
                </button>
                <button className="cursor-not-allowed">
                  <img src={Google} alt="apple download" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
